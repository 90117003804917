import { invokeApi } from '../bl_libs/invokeApi';
import {LOCAL_STORAGE_KEYS} from "../utils/constants";
import {use_e2ee} from "../utils/utils";


export const Assess8 = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/assess8/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/assess8/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/assess8/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/assess8/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        console.log('assess8 update candidate --> ', data);
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/assess8/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const Level3 = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level3/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level3/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level3/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level3/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        console.log('assess8 update candidate --> ', data);
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level3/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const Level6 = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level6/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level6/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level6/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level6/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        console.log('assess8 update candidate --> ', data);
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level6/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const Level7 = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level7/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level7/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level7/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level7/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        console.log('assess8 update candidate --> ', data);
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level7/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const Level9 = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level9/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level9/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level9/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level9/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        console.log('assess8 update candidate --> ', data);
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level9/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const Category4 = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/cat4/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/cat4/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/cat4/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/cat4/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        console.log('assess8 update candidate --> ', data);
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/cat4/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const Category11 = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/cat11/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/cat11/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/cat11/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/cat11/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        console.log('assess8 update candidate --> ', data);
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/cat11/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const Category108 = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/cat108/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/cat108/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/cat108/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/cat108/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        console.log('assess8 update candidate --> ', data);
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/cat108/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const Packages = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/packages/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/packages/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/packages/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/packages/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        console.log('packages update candidate --> ', data);
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/packages/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const Assess8_translations = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/assess8_translation/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/assess8_translation/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/assess8_translation/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/assess8_translation/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/assess8_translation/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const Level3_translations = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level3_translation/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level3_translation/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level3_translation/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level3_translation/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level3_translation/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const Level6_translations = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level6_translation/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level6_translation/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level6_translation/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/level6_translation/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/level6_translation/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const v3Level1Area = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/v3_level1_area/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level1_area/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level1_area/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level1_area/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/v3_level1_area/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const v3Level2Stakeholder = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/v3_level2_stakeholder/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level2_stakeholder/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level2_stakeholder/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level2_stakeholder/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/v3_level2_stakeholder/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const v3Level3Dimension = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/v3_level3_dimension/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level3_dimension/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level3_dimension/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level3_dimension/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/v3_level3_dimension/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const v3Level5Competency = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/v3_level5_competency/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level5_competency/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level5_competency/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level5_competency/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/v3_level5_competency/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const v3Level7DQCode = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/v3_level7_dqcode/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level7_dqcode/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level7_dqcode/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level7_dqcode/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/v3_level7_dqcode/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const v3Level8Unit = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/v3_level8_unit/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level8_unit/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level8_unit/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level8_unit/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/v3_level8_unit/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const v3Level9Session = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/v3_level9_session/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level9_session/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level9_session/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level9_session/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/v3_level9_session/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const v3Level10Element = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/v3_level10_element/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level10_element/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level10_element/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level10_element/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/v3_level10_element/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}

export const v3Level11Rubric = {
    postData: (data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/v3_level11_rubric/post',
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = { data };
        return invokeApi(requestObj, use_e2ee());
    },
    listData: () => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level11_rubric/list',
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    preRemoveCheck: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level11_rubric/pre_remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    deleteEntity: (id) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        return invokeApi({
            path   : '/admin/meta_data/v3_level11_rubric/remove/' + id,
            method : 'GET', headers : { 'Authorization' : `Bearer ${token}` }
        }, use_e2ee());
    },
    updateEntity: (id, data) => {
        const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.auth_token));
        let requestObj = {
            path   : '/admin/meta_data/v3_level11_rubric/update/' + id,
            method : 'POST', headers : { 'Authorization' : `Bearer ${token}` }
        };
        requestObj['postData'] = data;
        return invokeApi(requestObj, use_e2ee());
    }
}