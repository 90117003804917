import React, {useState} from 'react';
import {Link, useParams} from "react-router-dom";
import DisplayMetaWithImport from "../components/DisplayMetaWithImport.comp";
import {formatDate} from "../../../utils/utils";

import {
    v3Level3Dimension
} from "../../../services/metadata.service";


const columns = [
    {name: 'DQM#', key: 0, json_key: 'dqm_no', data_key: 'dqm_no'},
    {name: 'Stakeholder#', key: 1, json_key: 'stakeholder_no', data_key: 'stakeholder_no'},
    {name: 'Code', key: 2, json_key: 'code', data_key: 'code'},
    {name: 'Name', key: 3, json_key: 'full_name', data_key: 'full_name'},
    {name: 'Definition', key: 4, json_key: 'definition', data_key: 'definition'},
    {name: 'Structure', key: 5, json_key: 'structure', data_key: 'structure'},
    {name: 'Goals', key: 6, json_key: 'goals', data_key: 'goals'},
]


const
    tableCols = [
        {
            label: 'DQM#',
            field: 'dqm_no', width: 150
        },
        {
            label: 'Stakeholder#',
            field: 'stakeholder_no', width: 150
        },
        {
            label: 'Code',
            field: 'code', width: 150
        },
        {
            label: 'Full Name',
            field: 'full_name', width: 150
        },
        {
            label: 'Definition',
            field: 'definition', sort: 'disabled', width: 150
        },
        {
            label: 'Created',
            field: 'createdAt', width: 150
        },
        {
            label: 'Updated',
            field: 'updatedAt', width: 150
        },
        {
            label: 'Actions',
            field: 'actions', sort: 'disabled', searchable: false
        }
    ]


export default function Level3DimensionPage() {

    const { url_param_str } = useParams();
    const search_query = url_param_str.split('=').length > 1 ? url_param_str.split('=')[1]  : '';

    const table_row__additional_rendering = (row) => {
        const create_data = formatDate(row.createdAt) + ',\n ' + (row.createdBy ? row.createdBy : '');
        row.createdAt = create_data;

        const update_data = formatDate(row.updatedAt) + ',\n ' + (row.updatedBy ? row.updatedBy : '');
        row.updatedAt = update_data;

        return row;
    }

    return (
        <DisplayMetaWithImport
            title={'Level3 Dimension'}
            modal_title={'Level3 Dimension - Data Preview'}
            sheet_name={'Level3 (Dimension)'}
            columns={columns}
            tableCols={tableCols}
            tableSpRender={table_row__additional_rendering}
            search_query={search_query}
            listAPI={v3Level3Dimension.listData}
            postAPI={v3Level3Dimension.postData}
            deleteAPI={v3Level3Dimension.deleteEntity}
            updateAPI={v3Level3Dimension.updateEntity}
            preRemoveAPI={v3Level3Dimension.preRemoveCheck}
        />
    );
}
