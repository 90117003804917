import React, {useState} from 'react';
import {Link, useParams} from "react-router-dom";
import DisplayMetaWithImport from "../components/DisplayMetaWithImport.comp";
import {formatDate} from "../../../utils/utils";

import {
    v3Level11Rubric
} from "../../../services/metadata.service";

// name: display on preview top
// key: column# of excel sheet
// json_key: key for posting data to api
const columns = [
    {name: 'DQCO#', key: 0, json_key: 'element_no', data_key: 'dqc_no'},
    {name: 'Unit#', key: 1, json_key: 'unit_no', data_key: 'unit_no'},
    {name: 'Session#', key: 2, json_key: 'session_no', data_key: 'session_no'},
    {name: 'Level_9_Definition', key: 3, json_key: 'definition', data_key: 'level9_definition'},
    {name: 'Element#', key: 4, json_key: 'dqc_no', data_key: 'element_no'},
    {name: 'Level_10_Code', key: 5, json_key: 'code', data_key: 'level10_code'},
    {name: 'Level_10_Definition', key: 6, json_key: 'code', data_key: 'level10_definition'},
    {name: 'Code', key: 7, json_key: 'code', data_key: 'code'},
    {name: 'Definition', key: 8, json_key: 'definition', data_key: 'definition'},
]

const tableCols = [
    {
        label: 'Code',
        field: 'code', width: 150
    },
    {
        label: 'Element#',
        field: 'element_no', width: 150
    },
    {
        label: 'Definition',
        field: 'definition', sort: 'disabled', width: 150
    },
    {
        label: 'Created',
        field: 'createdAt', width: 150
    },
    {
        label: 'Updated',
        field: 'updatedAt', width: 150
    },
    {
        label: 'Actions',
        field: 'actions', sort: 'disabled', searchable: false
    }
]


export default function Level11RubricPage() {

    const { url_param_str } = useParams();
    const search_query = url_param_str.split('=').length > 1 ? url_param_str.split('=')[1]  : '';

    const table_row__additional_rendering = (row) => {
        const create_data = formatDate(row.createdAt) + ',\n ' + (row.createdBy ? row.createdBy : '');
        row.createdAt = create_data;

        const update_data = formatDate(row.updatedAt) + ',\n ' + (row.updatedBy ? row.updatedBy : '');
        row.updatedAt = update_data;

        return row;
    }

    return (
        <DisplayMetaWithImport
            title={'Level11 Rubric'}
            modal_title={'Level11 Rubric - Data Preview'}
            sheet_name={'Level11 (Rubric)'}
            columns={columns}
            tableCols={tableCols}
            tableSpRender={table_row__additional_rendering}
            search_query={search_query}
            listAPI={v3Level11Rubric.listData}
            postAPI={v3Level11Rubric.postData}
            deleteAPI={v3Level11Rubric.deleteEntity}
            updateAPI={v3Level11Rubric.updateEntity}
            preRemoveAPI={v3Level11Rubric.preRemoveCheck}
        />
    );
}
