import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import {
    RouteWithLayout,
    PrivateRouteWithLayout,
    PreAuthRouteWithLayout
} from './components';

import {
    Main as MainLayout,
    Minimal as MinimalLayout
} from './layouts';

import {
    Login as LoginPage,
    Logout as LogoutPage,
    Register as RegisterPage,
    InitPasswordReset as InitPasswordResetPage,
    PasswordReset as PasswordResetPage,
    ChangePassword as ChangePasswordPage,
    ConfirmEmail as ConfirmEmailPage,
    Home as HomePage,
    NotFound as NotFoundPage,
    AppInfo as AppInfoPage,
    Assess8Instrument as Assess8InstrumentPage,
    Level3 as Level3Page,
    Level6 as Level6Page,
    Level7 as Level7Page,
    Level9 as Level9Page,
    Cat4 as Cat4Page,
    Cat11 as Cat11Page,
    Cat108 as Cat108Page,
    Assess8_translations as Assess8_translationsPage,
    Level3_translations as Level3_translationsPage,
    Level6_translations as Level6_translationsPage,
    v3Level1_Area as V3Level1AreaPage,
    v3Level2_Stakeholder as V3Level2StakeholderPage,
    v3Level3_Dimension as V3Level3DimensionPage,
    v3Level5_Competency as V3Level5CompetencyPage,
    v3Level7_DQCode as v3Level7DQCodePage,
    v3Level8_Unit as v3Level8UnitPage,
    v3Level9_Session as v3Level9SessionPage,
    v3Level10_Element as v3Level10ElementPage,
    v3Level11_Rubric as v3Level11RubricPage,
    OrganizationsAccounts as OrganizationsAccountsPage,
    OrganizationsOrders as OrganizationsOrdersPage,
    AllPendingOrders as AllPendingOrdersPage,
    OrganizationsAll_Areas as OrganizationsAll_AreasPage,
    OrganizationsAll_Badges as OrganizationsAll_BadgesPage,
    OrganizationsAll_Packages as OrganizationsAll_PackagesPage,
    OrganizationsArea_UI_Conf as OrganizationsArea_UI_ConfPage,
    OrganizationsBadge_UI_Conf as OrganizationsBadge_UI_ConfPage,
    OrganizationsConfig as OrganizationsConfigPage,
    OrganizationsList as OrganizationsListPage,
    OrganizationsSubscriptions as OrganizationsSubscriptionsPage,
    OrganizationsProfileLookup as OrganizationsProfileLookupPage,
    OrganizationsSections as OrganizationsSectionsPage,
    Packages as PackagesPage,
    SettingsConfigs as SettingsConfigsPage,
    SettingsBuilds as SettingsBuildsPage,
    UsersList as UsersListPage,
    EnQaMode as EnQaModePage,
    DsQaMode as DsQaModePage
} from './pages';
import {AppPendingOrders} from "./pages";

const Routes = () => {
    return (
        <Switch>
            <Redirect
                exact
                from="/"
                to="/home"
            />
            <PreAuthRouteWithLayout
                component={LoginPage}
                exact
                layout={MinimalLayout}
                path="/login"
            />
            <PreAuthRouteWithLayout
                component={RegisterPage}
                exact
                layout={MinimalLayout}
                path="/register"
            />
            <PreAuthRouteWithLayout
                component={InitPasswordResetPage}
                exact
                layout={MinimalLayout}
                path="/account/recover"
            />
            <PreAuthRouteWithLayout
                component={PasswordResetPage}
                exact
                layout={MinimalLayout}
                no_redirect={true}
                path="/auth/reset/password"
            />


            <PrivateRouteWithLayout
                component={ConfirmEmailPage}
                exact
                layout={MinimalLayout}
                path="/auth/confirm/email"
            />
            <PrivateRouteWithLayout
                component={HomePage}
                exact
                layout={MainLayout}
                path="/home"
            />
            <PrivateRouteWithLayout
                component={ChangePasswordPage}
                exact
                layout={MainLayout}
                path="/password/change"
            />
            <PrivateRouteWithLayout
                component={LogoutPage}
                exact
                layout={MainLayout}
                path="/logout"
            />

            {/*########################*/}
            {/*### META DATA -- ROUTES ###*/}
            {/*########################*/}

            <PrivateRouteWithLayout
                component={Assess8InstrumentPage}
                exact
                layout={MainLayout}
                path="/meta_data/assess8/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={Level3Page}
                exact
                layout={MainLayout}
                path="/meta_data/level3/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={Level6Page}
                exact
                layout={MainLayout}
                path="/meta_data/level6/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={Level7Page}
                exact
                layout={MainLayout}
                path="/meta_data/level7/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={Level9Page}
                exact
                layout={MainLayout}
                path="/meta_data/level9/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={Cat4Page}
                exact
                layout={MainLayout}
                path="/meta_data/cat4/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={Cat11Page}
                exact
                layout={MainLayout}
                path="/meta_data/cat11/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={Cat108Page}
                exact
                layout={MainLayout}
                path="/meta_data/cat108/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={PackagesPage}
                exact
                layout={MainLayout}
                path="/meta_data/packages/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={Assess8_translationsPage}
                exact
                layout={MainLayout}
                path="/meta_data/assess8_translation/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={Level3_translationsPage}
                exact
                layout={MainLayout}
                path="/meta_data/level3_translation/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={Level6_translationsPage}
                exact
                layout={MainLayout}
                path="/meta_data/level6_translation/:url_param_str"
            />

            <PrivateRouteWithLayout
                component={V3Level1AreaPage}
                exact
                layout={MainLayout}
                path="/meta_data/v3_level1_area/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={V3Level2StakeholderPage}
                exact
                layout={MainLayout}
                path="/meta_data/v3_level2_stakeholder/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={V3Level3DimensionPage}
                exact
                layout={MainLayout}
                path="/meta_data/v3_level3_dimension/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={V3Level5CompetencyPage}
                exact
                layout={MainLayout}
                path="/meta_data/v3_level5_competency/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={v3Level7DQCodePage}
                exact
                layout={MainLayout}
                path="/meta_data/v3_level7_dq_code/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={v3Level8UnitPage}
                exact
                layout={MainLayout}
                path="/meta_data/v3_level8_unit/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={v3Level9SessionPage}
                exact
                layout={MainLayout}
                path="/meta_data/v3_level9_session/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={v3Level10ElementPage}
                exact
                layout={MainLayout}
                path="/meta_data/v3_level10_element/:url_param_str"
            />
            <PrivateRouteWithLayout
                component={v3Level11RubricPage}
                exact
                layout={MainLayout}
                path="/meta_data/v3_level11_rubric/:url_param_str"
            />


            {/*########################*/}
            {/*### ORGANIZATIONS -- ROUTES ###*/}
            {/*########################*/}
            <PrivateRouteWithLayout
                component={OrganizationsAccountsPage}
                exact
                layout={MainLayout}
                path="/org/accounts"
            />

            <PrivateRouteWithLayout
                component={OrganizationsOrdersPage}
                exact
                layout={MainLayout}
                path="/org/orders"
            />

            <PrivateRouteWithLayout
                component={AllPendingOrdersPage}
                exact
                layout={MainLayout}
                path="/org/allorders"
            />

            <PrivateRouteWithLayout
                component={OrganizationsAll_AreasPage}
                exact
                layout={MainLayout}
                path="/org/all_areas"
            />

            <PrivateRouteWithLayout
                component={OrganizationsAll_BadgesPage}
                exact
                layout={MainLayout}
                path="/org/all_badges"
            />

            <PrivateRouteWithLayout
                component={OrganizationsAll_PackagesPage}
                exact
                layout={MainLayout}
                path="/org/all_packages"
            />

            <PrivateRouteWithLayout
                component={OrganizationsArea_UI_ConfPage}
                exact
                layout={MainLayout}
                path="/org/area_ui_conf"
            />

            <PrivateRouteWithLayout
                component={OrganizationsBadge_UI_ConfPage}
                exact
                layout={MainLayout}
                path="/org/badge_ui_conf"
            />

            <PrivateRouteWithLayout
                component={OrganizationsConfigPage}
                exact
                layout={MainLayout}
                path="/org/configs"
            />

            <PrivateRouteWithLayout
                component={OrganizationsListPage}
                exact
                layout={MainLayout}
                path="/org/list"
            />

            <PrivateRouteWithLayout
                component={OrganizationsSubscriptionsPage}
                exact
                layout={MainLayout}
                path="/org/subscriptions"
            />

            <PrivateRouteWithLayout
                component={OrganizationsProfileLookupPage}
                exact
                layout={MainLayout}
                path="/org/profile_lookup"
            />

            <PrivateRouteWithLayout
                component={OrganizationsSectionsPage}
                exact
                layout={MainLayout}
                path="/org/sections"
            />


            {/*########################*/}
            {/*### Miscellaneous -- ROUTES ###*/}
            {/*########################*/}

            <PrivateRouteWithLayout
                component={SettingsConfigsPage}
                exact
                layout={MainLayout}
                path="/settings/configs"
            />

            <PrivateRouteWithLayout
                component={SettingsBuildsPage}
                exact
                layout={MainLayout}
                path="/settings/builds"
            />

            <PrivateRouteWithLayout
                component={UsersListPage}
                exact
                layout={MainLayout}
                path="/users/list"
            />

            <RouteWithLayout
                component={AppInfoPage}
                exact={false}
                layout={MinimalLayout}
                path="/app_info"
            />

            <RouteWithLayout
                component={EnQaModePage}
                exact={false}
                layout={MinimalLayout}
                path="/en_qa_mode"
            />

            <RouteWithLayout
                component={DsQaModePage}
                exact={false}
                layout={MinimalLayout}
                path="/ds_qa_mode"
            />

            <RouteWithLayout
                component={NotFoundPage}
                exact={false}
                layout={MinimalLayout}
                path="/"
            />

        </Switch>
    );
};

export default Routes;
