import React, {useState} from 'react';
import {Link, useParams} from "react-router-dom";
import DisplayMetaWithImport from "../components/DisplayMetaWithImport.comp";
import {formatDate} from "../../../utils/utils";

import {
    v3Level1Area
} from "../../../services/metadata.service";


const columns = [
    {name: 'DQA#', key: 0, json_key: 'dqa_no', data_key: 'dqa_no'},
    {name: 'Code', key: 1, json_key: 'code', data_key: 'code'},
    {name: 'Name', key: 2, json_key: 'full_name', data_key: 'full_name'},
    {name: 'Definition', key: 3, json_key: 'definition', data_key: 'definition'},
    {name: 'Moral Guiding Principles', key: 4, json_key: 'moral_guiding_principles', data_key: 'moral_guiding_principles'},
]


const
    tableCols = [
        {
            label: 'DQA#',
            field: 'dqa_no', width: 150
        },
        {
            label: 'Code',
            field: 'code', width: 150
        },
        {
            label: 'Full Name',
            field: 'full_name', width: 150
        },
        {
            label: 'Definition',
            field: 'definition', sort: 'disabled', width: 150
        },
        {
            label: 'Created',
            field: 'createdAt', width: 150
        },
        {
            label: 'Updated',
            field: 'updatedAt', width: 150
        },
        {
            label: 'Actions',
            field: 'actions', sort: 'disabled', searchable: false
        }
    ]


export default function Level1AreaPage() {

    const { url_param_str } = useParams();
    const search_query = url_param_str.split('=').length > 1 ? url_param_str.split('=')[1]  : '';

    const table_row__additional_rendering = (row) => {
        const create_data = formatDate(row.createdAt) + ',\n ' + (row.createdBy ? row.createdBy : '');
        row.createdAt = create_data;

        const update_data = formatDate(row.updatedAt) + ',\n ' + (row.updatedBy ? row.updatedBy : '');
        row.updatedAt = update_data;

        return row;
    }

    return (
        <DisplayMetaWithImport
            title={'Level1 Area'}
            modal_title={'Level1 Area - Data Preview'}
            sheet_name={'Level1 (Area)'}
            columns={columns}
            tableCols={tableCols}
            tableSpRender={table_row__additional_rendering}
            search_query={search_query}
            listAPI={v3Level1Area.listData}
            postAPI={v3Level1Area.postData}
            deleteAPI={v3Level1Area.deleteEntity}
            updateAPI={v3Level1Area.updateEntity}
            preRemoveAPI={v3Level1Area.preRemoveCheck}
        />
    );
}
